
import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Buttons from "../../Commons/Buttons.vue";
// @ts-ignore
// import TableList from "./TableList.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";

import {
	prepareSelectedData,
	prepareTableData,
	prepareTableContent,
	preparedColumnDefsGrid
	// @ts-ignore
} from "@/utils/CustomizeViewData";
// @ts-ignore
import ParamService from "@/services/params-service";

import {
	RequestCampaign,
	ResultPaginate,
	// @ts-ignore
} from "@/interfaces/request_campaign";
// @ts-ignore
import { isUndefined, isNull, isEmpty } from "lodash";
// @ts-ignore
import { getFilters, getOptions } from "./Utils";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { MessageTypes } from "@/interfaces/proccess";
export default Vue.extend({
	name: "RequestCampaignList",
	props: {},
	components: {
		Buttons,
		Alertize,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
	},
	data: () => ({
		title: "Request Campaign List",
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			headers: [],
			items: [],
		},
		fields: {},
		context: null,
		selectionRows: "single",
		T_FILTER: getFilters(),
		T_SORT: getOptions(),
	}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {
		this.$nextTick(async () => {
			await this.getPaginated();
			this.context = { componentParent: this };
		});
	},
	computed: {

		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("internationalization", ["getLanguage"]),

		getResultPaginate(): ResultPaginate {
			return this.$store.state.request_campaign.result_paginate;
		},

		getRequestCampaigns(): RequestCampaign[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		getCountRequestCampaign() {
			return this.$t("show.requestCampaign", {
				total: this.getRequestCampaigns.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: false,
				entityName: "Request Campaign",
				redirect: "RequestCampaignEdit",
				activityLog: "",
				edit: true,
				sendEmail: true,
				duplicated: false,
				log: false,
				filters: this.T_FILTER,
				options: this.T_SORT,
				minWidthActions: 100,
				maxWidthActions: 130
			};
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        gerRowsData(){
            return this.table.items || [];
        }

	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),
		...mapActions("request_campaign", [
            "sendEmail",
        ]),
		...mapActions("proccess", ["setNotification"]),

		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		async sendEmailCPI(params: any){
			await this.setLoadingData(TypeLoading.loading);
			await this.sendEmail({
				id: params.id,
			}).catch((err: { message: any; }) => {
				this.setNotification(this.getMsgError(false, "", err.message));
				this.setLoadingData();
			});
			this.setLoadingData();
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},

		async getPaginated(uTable: boolean = true) {
			await this.setLoadingData(TypeLoading.loading);
			await this.$store.dispatch(
				"request_campaign/paginated",
				await ParamService.getParams(
					this.paginated,
					this.filters,
					this.options
				)
			);
			if(uTable){
				await this.getTableData({ view: this.$route?.name });
			}else{
				await this.updateItemsTable();
			}
			await this.setLoadingData();
		},

		async handleSave(event: any) {
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},
		getMsgError(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Error",
				message: message,
				type: MessageTypes.ERROR,
				show: true,
				details: "",
				to: redirec ? to : undefined,
				btn_text: "Close",
			};
			return msgSucces;
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async updateItemsTable(){
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			this.fields = await prepareSelectedData(selected);

			this.table.items = await prepareTableContent(this.fields, this.getRequestCampaigns);
			this.$forceUpdate();
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getRequestCampaigns,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "requestCampaign",
				entities: this.getRequestCampaigns,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		async updateParams(params: {
			filters: any;
			options: SortingOption;
		}, uTable: boolean = true) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { key: any, options: SortingOption; filter: any }) {
			this.setFilter({ key: params.key, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			}, false);
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			this.updatePaginate(1);
			await this.getPaginated(false);
		},

		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},

	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	}
});
